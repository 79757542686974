class Line {
  constructor(dotOne, dotTwo, canvas) {
    this.dotOne = dotOne;
    this.dotTwo = dotTwo;
    this.dead = false;
    this.deadCount = 0;
    this.new = true;
    this.newCount = 10;
    this.colorStep = 1;
    this.alpha = 0;
    this.canvas = canvas;
  }

  draw() {
    var difference = function (a, b) {
      return Math.abs(a - b);
    };
    if (
      difference(this.dotOne.x, this.dotTwo.x) > 250 ||
      difference(this.dotOne.y, this.dotTwo.y) > 250
    )
      return;

    const ctx = this.canvas.getContext("2d");
    ctx.strokeStyle = this.getColour();
    ctx.lineWidth = 1;

    ctx.beginPath();
    ctx.moveTo(this.dotOne.x - 4, this.dotOne.y - 4);
    ctx.lineTo(this.dotTwo.x - 4, this.dotTwo.y - 4);
    ctx.stroke();
  }

  getColour() {
    if (this.new) {
      this.alpha += 0.05;
      if (this.alpha >= 1) {
        this.alpha = 1;
        this.new = false;
      }
    } else if (this.dead) {
      this.alpha -= 0.05;
      if (this.alpha <= 0) {
        this.alpha = 0;
        this.deadCount += 1;
      }
    }
    return `rgba(0, 39, 124, ${this.alpha})`;
  }
}

export default Line;
